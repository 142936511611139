import React from 'react'

import Accordion from 'react-bootstrap/Accordion';

const Faqs = (props) => {
  return (
    <div>
               
     <Accordion style={{margin:"10px 0",boxShadow:"rgba(99, 99, 99, 0.2) 0px 5px 15px",borderRadius:"40px"}} >
      <Accordion.Item  eventKey="0" >
        <Accordion.Header className='acodiantitle'>{props.title}</Accordion.Header>
        <Accordion.Body className='acodianbody'>
          {props.body}
        </Accordion.Body>
      </Accordion.Item>
      
    </Accordion>
                 
    </div>
  )
}

export default Faqs